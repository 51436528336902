import React, { useState, useEffect, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import * as styles from "./index.module.scss"
import Container from "~/components/ui/container"
import Testimonials from "~/components/ui/testimonials"
import Urus from "~/images/internships/urus.svg"
import Alta from "~/images/internships/alta.svg"
import Genex from "~/images/internships/genex.svg"
import Peak from "~/images/internships/peak.svg"
import SCCL from "~/images/internships/sccl.svg"
import Vas from "~/images/internships/vas.svg"
import AgSource from "~/images/internships/ag-source.svg"
import TransOva from "~/images/internships/trans-ova.svg"
import useInternshipTestimonials from "~/hooks/cms/useInternshipTestimonials"
import useInternshipOpportunities from "~/hooks/cms/useInternshipOpportunities"
import Link from "~/components/link"

const HeroSection: React.FC = () => {
  const testimonials = useInternshipTestimonials()
  const opportunities = useInternshipOpportunities()

  const [index, setIndex] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "internships_bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  const memoizedOpportunities = useMemo(() => {
    return opportunities.map(obj => {
      return obj.node.data
    })
  }, [])

  return (
    <section className={styles.root}>
      <div className={styles.background}>
        <div className={styles.background__img}>
          <GatsbyImage
            alt="Workers"
            image={data.file.childImageSharp.gatsbyImageData}
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
        <Container>
          <h1
            className={classNames(
              "headline--decorated",
              styles.info__headline__mobile
            )}
          >
            <span>Explore internships</span> <br /> Across the Urus family
          </h1>
        </Container>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.infoWrapper}>
          <Container>
            <div className={styles.info}>
              <h1
                className={classNames(
                  "headline--decorated",
                  styles.info__headline__desktop
                )}
              >
                <span>Explore internships</span> <br /> Across the Urus family
              </h1>
              <p>
                We're passionate about developing future leaders in the bovine
                industry and we aim to build staff in full time careers.
              </p>
              <div className={styles.testimonial__desktop}>
                <Testimonials
                  title="Hear from our Previous Interns"
                  picture={testimonials[index].node.data.picture}
                  name={testimonials[index].node.data.name.text}
                  university={testimonials[index].node.data.university.text}
                  position={testimonials[index].node.data.position.text}
                  text={testimonials[index].node.data.text.text}
                  setIndex={setIndex}
                  index={index}
                  itemCount={testimonials.length}
                  maxItems={3}
                />
              </div>
            </div>
          </Container>
        </div>

        <div className={styles.main}>
          <p className={styles.main__title}>
            Across the URUS family of companies you will find a variety of
            internship opportunities including:
          </p>
          <div className={styles.main__lists}>
            <ul>
              <li>Reproductive Management</li>
              <li>Sales</li>
              <li>Genetic Consulting</li>
              <li>Animal Welfare</li>
            </ul>
            <ul>
              <li>Marketing</li>
              <li>Labs & Research</li>
              <li>Calf Management</li>
            </ul>
          </div>
          <p className={styles.main__cta}>
            Click on each company logo to learn more about internship
            opportunities.
          </p>
          <div className={styles.main__logos}>
            <a href="/">
              <Urus />
            </a>
            <a href="https://agsource.com/career/">
              <AgSource />
            </a>
            <a href="https://us.altagenetics.com/internship/">
              <Alta />
            </a>
            <a href="https://genex.coop/internships/">
              <Genex />
            </a>
            <a href="https://peakgenetics.com/internships/">
              <Peak />
            </a>
            <a href="https://sccl.com/english/">
              <SCCL />
            </a>
            <a href="https://transova.com/youth/">
              <TransOva />
            </a>
            <a href="https://vas.com/career/">
              <Vas />
            </a>
          </div>
        </div>
        <div className={styles.testimonial__mobile}>
          <Testimonials
            title="Hear from our Previous Interns"
            picture={testimonials[index].node.data.picture}
            name={testimonials[index].node.data.name.text}
            university={testimonials[index].node.data.university.text}
            position={testimonials[index].node.data.position.text}
            text={testimonials[index].node.data.text.text}
            setIndex={setIndex}
            index={index}
            itemCount={testimonials.length}
          />
        </div>
      </div>
    </section>
  )
}

export default HeroSection
